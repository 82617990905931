import {
  EFormControlRules,
  ETextFieldType,
  IContactForm,
  IFormRulesConfig,
  IRuleProps
} from '@shared/models/form.interface';

const DEFAULT_NAME_ERROR = 'Merci de renseigner votre nom';
const DEFAULT_EMAIL_ERROR = 'Merci de renseigner un email valide';
const DEFAULT_MESSAGE_ERROR = 'Merci de saisir un message';
const NAME_LABEL = 'NOM';
const EMAIL_LABEL = 'EMAIL';
const MESSAGE_LABEL = 'MESSAGE';

export const contactFormConf = {
  userName: {
    label: NAME_LABEL,
    required: true,
    defaultError: DEFAULT_NAME_ERROR,
    type: ETextFieldType.TEXT,
    rules: [
      {
        type: EFormControlRules.MIN_LENGTH,
        props: { minLength: 2 }
      }
    ]
  },
  userEmail: {
    label: EMAIL_LABEL,
    required: true,
    type: ETextFieldType.EMAIL,
    defaultError: DEFAULT_EMAIL_ERROR,
    rules: [
      {
        type: EFormControlRules.EMAIL
      }
    ]
  },
  userMessage: {
    label: MESSAGE_LABEL,
    required: true,
    rows: 4,
    type: ETextFieldType.TEXTAREA,
    defaultError: DEFAULT_MESSAGE_ERROR
  }
} as IContactForm;

export const formRules: IFormRulesConfig = {
  [EFormControlRules.MIN_LENGTH]: (
    value: string,
    { minLength = 0 }: IRuleProps
  ) =>
    (value.trim().length < minLength &&
      `Veuillez saisir au moins ${minLength} caractère${minLength > 1 ? 's' : ''}`) ||
    undefined,
  [EFormControlRules.EMAIL]: (value: string) =>
    (!/^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/i.test(value) &&
      DEFAULT_EMAIL_ERROR) ||
    undefined
};
