import React, { useEffect, useRef, useState } from 'react';

import InfiniteCarousel from '@shared/components/infiniteCarousel/infiniteCarousel';
import useIntersectionObserver from '@shared/hooks/useIntersectionObserver';
import { useHome } from '@features/homePage/homePageContext';
import { IHomeComponentPropsInterface } from '@shared/models/component.interface';
import { HOME } from '@constants/texts';
import './homeForeword.scss';

const HomeForeword: React.FC<IHomeComponentPropsInterface> = ({ sectionId }) => {
  const [activateAnimation, setActivateAnimation] = useState(false);
  const elementRef = useRef(null);
  const isVisible = useIntersectionObserver(elementRef);
  const { setCurrentSectionId } = useHome();

  useEffect(() => {
    isVisible && setCurrentSectionId(sectionId);

    setTimeout(() => {
      setActivateAnimation(isVisible);
    }, 400);
  }, [isVisible]);

  return (
    <div ref={elementRef} className="home-foreword pf-fullscreen">
      <div className="pf-section-container">
        <div
          className={`home-foreword-main-container pf-appear-animation ${activateAnimation ? 'active' : ''}`}
        >
          <div className="pf-section-title">{HOME.foreword.title}</div>

          <div className="home-foreword-intro">{HOME.foreword.intro}</div>
        </div>

        <div
          className={`pf-fade-in-animation ${activateAnimation ? 'active' : ''}`}
        >
          <InfiniteCarousel></InfiniteCarousel>
        </div>
      </div>
    </div>
  );
};

export default HomeForeword;
