import IJob from '@shared/models/job.interface';

const jobListe: IJob[] = [
	{
		startDate: '09-2024',
		company: 'Freelance',
		role: 'Développeur Full Stack',
		description: 'Développement de solution digitales sur mesure',
		realisations: [
			'Concevoir et développer des applications web front-end et back-end',
			'Comprendre le besoin client et Intégrer des solutions techniques adaptées',
			'Assurer la maintenance et les mises à jour des systèmes en production',
			'Optimiser les performances et la sécurité des applications',
			'Collaborer avec les équipes pour garantir une livraison dans les délais'
		],
		isCurrent: true
	},
	{
		startDate: '10-2017',
		endDate: '12-2020',
		company: 'Sogeti',
		role: 'Consultant applications',
		description:
			'Développement de solutions innovantes et consulting client',
		realisations: [
			"Prototypage d’une borne holographique avec capteur de mouvement et détection d'émotions",
			'Intégration de la méthode Agile pour la gestion de projet via Redmine',
			'Prise en compte des enjeux clients et adoption de la posture de consultant IT pour répondre aux besoins techniques'
		]
	},
	{
		startDate: '12-2020',
		endDate: '03-2023',
		company: 'Swisslife',
		role: 'Développeur Full Stack',
		description: 'Développement sur mesure adapté aux besoins métiers',
		realisations: [
			"Migration d'une application AngularJS vers Angular",
			'Réécriture de l’espace client en Angular, respect du clean code',
			'Migration back-end Express vers NestJS',
			"Remplacement de l'application mobile par une Progressive Web App (PWA)",
			'Écriture de tests unitaires avec Jasmine/Jest et de tests de charge',
			'Gestion de la chaîne CI/CD on-premise et cloud AWS via Bitbucket, Jenkins, AWS CloudFormation',
			'Respect des pratiques de qualité du code'
		]
	},
	{
		startDate: '03-2023',
		endDate: '08-2024',
		company: 'Swisslife',
		role: 'Lead Développeur',
		description: 'Responsable technique opérationnel de l’espace client',
		realisations: [
			'Conception technique et ateliers de faisabilité pour garantir des solutions fiables et adaptées aux besoins fonctionnels',
			'Revue de code et veille au respect des bonnes pratiques du Clean Code',
			'Identification et résolution de problèmes techniques',
			'Mise à jour et implémentation de la documentation technique',
			'Création de tableaux de bord de suivi de performances pour une réponse proactive aux anomalies.'
		]
	}
];

export default jobListe;
