import React, { createContext, useState, ReactNode, useContext } from 'react';

interface HomeContextProps {
  isMobile: boolean;
  currentSectionId: number;
  setCurrentSectionId: (currentSectionId: number) => void;
}

const HomeContext = createContext<HomeContextProps | undefined>(undefined);

export const HomeProvider: React.FC<{ children: ReactNode }> = ({
  children
}) => {
  const [currentSectionId, setCurrentSectionId] = useState<number>(0);
  const isMobile = /Android|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i.test(navigator.userAgent);

  return (
    <HomeContext.Provider value={{ isMobile, currentSectionId, setCurrentSectionId }}>
      {children}
    </HomeContext.Provider>
  );
};

export const useHome = () => {
  const context = useContext(HomeContext);
  if (!context) throw new Error('useHome must be used within a HomeProvider');

  return context;
};
