import React, { useEffect, useState } from 'react';

import randomIntFromInterval from '@utils/math.utils';
import './typedTitle.scss';

const TypedTitle: React.FC<{ title: string, onTitleDisplayed: () => void }> = ({ title, onTitleDisplayed }) => {
	const [text, setText] = useState('');
	const textRef = React.useRef(text);

	const typeText = () => {
		const index = textRef.current.length;
		if (index >= title.length) {
			onTitleDisplayed();
			return;
		}

		setText(textRef.current + title[index]);
	};

	useEffect(() => {
		textRef.current = text;
		const typingSpeed = randomIntFromInterval(20, 100);
		setTimeout(typeText, typingSpeed);
	}, [text]);

	return (
		<div>
			<div className="terminal-wrapper">
				<span className="terminal-content">
					<span>{text}</span>
					<span className="cursor"></span>
				</span>
			</div>
		</div>
	);
};

export default TypedTitle;
