import React, { useEffect, useRef, useState } from 'react';

import TypedTitle from '@features/homePage/intro/typedTitle/typedTitle';
import ScrollingMouse from '@shared/components/scrollingMouse/scrollingMouse';
import useIntersectionObserver from '@shared/hooks/useIntersectionObserver';
import linkifyText from '@utils/text.utils';
import { useHome } from '@features/homePage/homePageContext';
import ITextLink from '@shared/models/text.interface';
import { IHomeComponentPropsInterface } from '@shared/models/component.interface';
import { HOME } from '@constants/texts';
import './homeIntro.scss';

const HomeIntro: React.FC<IHomeComponentPropsInterface> = ({
  scrollToRef,
  sectionId
}) => {
  const [isTitleDisplayed, setIsTitleDisplayed] = useState(false);
  const [activateAnimation, setActivateAnimation] = useState(false);
  const elementRef = useRef(null);
  const isVisible = useIntersectionObserver(elementRef);
  const { setCurrentSectionId } = useHome();

  const handleLink1Click = () => {
    const fileUrl = `${process.env.PUBLIC_URL}/assets/CV-pierrick-viart.pdf`; // Remplace par l'URL de ton fichier
    window.open(fileUrl, '_blank'); // Ouvre le fichier dans un nouvel onglet
  };

  const handleLink2Click = () => {
    scrollToRef && scrollToRef(1);
  };

  const links: ITextLink[] = [
    {
      linkText: HOME.intro.links[0],
      onClick: handleLink1Click
    },
    {
      linkText: HOME.intro.links[1],
      onClick: handleLink2Click
    }
  ];

  const lines = HOME.intro.welcome.split('\n');

  useEffect(() => {
    isVisible && setCurrentSectionId(sectionId);

    setTimeout(() => {
      setActivateAnimation(isVisible && isTitleDisplayed);
    }, 400);
  }, [isVisible, isTitleDisplayed]);

  return (
    <div ref={elementRef} className="home-intro pf-fullscreen">
      <div className="pf-section-container">
        <div className="home-intro-main-container">
          <TypedTitle
            onTitleDisplayed={() => {
              setIsTitleDisplayed(true);
            }}
            title={HOME.intro.title}
          />

          <div>
            <div
              className={`home-intro-main-container-description pf-appear-animation ${activateAnimation ? 'active' : ''}`}
            >
              {lines.map((line, index) => (
                <div key={index}>{linkifyText(line, links)}</div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className={`fade-in ${activateAnimation ? 'show' : ''}`}>
        <ScrollingMouse onButtonClick={() => scrollToRef && scrollToRef(1)} />
      </div>
    </div>
  );
};

export default HomeIntro;
