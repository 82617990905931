import React from 'react';

import { ISkill } from '@shared/models/skill.interface';
import './skill.scss';

const Skill: React.FC<{ skill: ISkill }> = ({ skill }) => {
	return (
		<div className="pf-skill">
				<skill.icon />
				{skill.name}
		</div>
	);
};

export default Skill;
