import { useEffect, useRef } from 'react';

import HomeIntro from './intro/homeIntro';
import HomeForeword from './foreword/homeForeword';
import HomeStepper from '@features/homePage/stepper/homeStepper';
import HomeKnowledges from '@features/homePage/knowledges/homeKnowledges';
import HomeExperiences from '@features/homePage/experiences/homeExperiences';
import HomeContact from '@features/homePage/contact/homeContact';
import { useHome } from '@features/homePage/homePageContext';
import { IHomeComponentConfig } from '@shared/models/component.interface';

const HomePage = () => {
  const { currentSectionId, isMobile } = useHome();
  const sectionIdRef = useRef(currentSectionId);
  const childRefs = useRef<(HTMLElement | null)[]>([]);
  const sections: IHomeComponentConfig[] = [
    { component: HomeIntro, sectionId: 0, hasScrollToRef: true },
    { component: HomeForeword, sectionId: 1 },
    { component: HomeKnowledges, sectionId: 2 },
    { component: HomeExperiences, sectionId: 3 },
    { component: HomeContact, sectionId: 4 }
  ];

  let scrollTicking = false;
  let wheelTicking = false;
  let isScrolling = false;
  let isChecking = false;
  let lastScrollY = window.scrollY;

  //LISTENERS
  const wheelListener = (event: WheelEvent) => {
    event.preventDefault();

    if (wheelTicking) {
      return;
    }
    wheelTicking = true;
    setTimeout(() => {
      wheelTicking = false;
    }, 100);

    if (isScrolling) {
      return;
    }

    const nextSectionId =
      event.deltaY > 0 ? sectionIdRef.current + 1 : sectionIdRef.current - 1;

    if (nextSectionId < 0 || nextSectionId >= childRefs.current.length) {
      return;
    }

    isScrolling = true;
    scrollToRef(nextSectionId);
  };

  const checkForScrollDone = () => {
    const position = window.scrollY;
    if (position !== lastScrollY) {
      lastScrollY = position;
      setTimeout(checkForScrollDone, 50);
      return;
    }

    isMobile && scrollToRef(sectionIdRef.current);
    isScrolling = false;
    isChecking = false;
  };

  const scrollListener = () => {
    if (scrollTicking) {
      return;
    }
    scrollTicking = true;

    setTimeout(() => {
      scrollTicking = false;
    }, 100);

    if (isChecking) {
      return;
    }
    isChecking = true;
    checkForScrollDone();
  };

  const keyDownListener = (event: KeyboardEvent) => {
    switch (event.key) {
      case 'ArrowDown':
      case 'PageDown':
        event.preventDefault();
        scrollToRef(sectionIdRef.current + 1);
        break;
      case 'ArrowUp':
      case 'PageUp':
        event.preventDefault();
        scrollToRef(sectionIdRef.current - 1);
        break;
      case 'Home':
        event.preventDefault();
        scrollToRef(0);
        break;
      case 'End':
        event.preventDefault();
        scrollToRef(childRefs.current.length - 1);
        break;
      default:
        break;
    }
  };

  // UTILS
  const scrollToRef = (sectionId: number) => {
    const ref: HTMLElement | null = childRefs.current[sectionId];
    if (!ref) {
      return;
    }
    ref.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const onStepClick = (stepId: number) => {
    scrollToRef(stepId);
  };

  // HOOKS
  useEffect(() => {
    sectionIdRef.current = currentSectionId;
  }, [currentSectionId]);

  useEffect(() => {
    window.addEventListener('scroll', scrollListener, { passive: true });
    window.addEventListener('keydown', keyDownListener, { passive: false });
    window.addEventListener('wheel', wheelListener, { passive: false });

    return () => {
      window.removeEventListener('scroll', scrollListener);
      window.removeEventListener('keydown', keyDownListener);
      window.removeEventListener('wheel', wheelListener);
    };
  }, []);

  return (
    <div id="scrollRef">
      <HomeStepper activeStep={currentSectionId} onStepClick={onStepClick} />

      {sections.map(({ component: Component, sectionId, hasScrollToRef }) => (
        <section
          key={sectionId}
         ref={(el) => (childRefs.current[sectionId] = el)}
        >
          <Component
            sectionId={sectionId}
            scrollToRef={hasScrollToRef ? scrollToRef : undefined}
          />
        </section>
      ))}
    </div>
  );
};

export default HomePage;
