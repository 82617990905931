import { createTheme } from '@mui/material/styles';

const textFieldTheme = createTheme({
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#fff',
          font: '14px/1.5 Apercu, sans-serif',
          '&.Mui-error': {
            color: 'red'
          }
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          width: '100%',
          transition: 'border-radius .3s ease, background-color .3s ease',
          '&:focus-within': {
            backgroundColor: '#1f485e'
          },
          '& textarea': {
            resize: 'vertical',
            minHeight: '100px',
            maxHeight: '30vh',
            overflow: 'auto'
          },
          '& textarea::after': {
            display: 'none'
          },
          '& .MuiFilledInput-notchedOutline': {
            borderColor: '#ccc'
          },
          '&:focus-within .MuiFilledInput-notchedOutline': {
            borderColor: '#fff'
          },
          '& .MuiInputLabel-root': {
            color: '#fff',
            font: '14px/1.5 Apercu, sans-serif'
          },
          '& .MuiInputLabel-root.Mui-focused': {
            '&.Mui-focused': {
              color: '#ffa61f'
            },
            '&.Mui-focused.Mui-error': {
              color: 'red'
            }
          },
          '& .MuiInputBase-input': {
            color: '#fff'
          },
          '& .MuiInputBase-input::placeholder': {
            color: '#fff'
          },
          '& .MuiInputBase-input:focus::placeholder': {
            color: 'transparent'
          }
        }
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          width: '100%',
          border: 'unset',
          backgroundColor: '#9caea9',
          '&.Mui-focused': {
            backgroundColor: 'unset'
          },
          '&::before': {
            borderBottom: 'none'
          },
          '&::after': {
            borderBottom: '2px solid #ffa61f'
          },
          '&.Mui-error::before': {
            borderBottom: '2px solid red'
          },
          '& textarea': {
            resize: 'vertical',
            minHeight: '100px',
            maxHeight: '30vh',
            overflow: 'auto'
          },
          '& .MuiInputBase-input': {
            color: '#fff'
          },
          '& .MuiInputBase-input::placeholder': {
            color: '#fff'
          },
          '& .MuiInputBase-input:focus::placeholder': {}
        }
      }
    }
  }
});

export default textFieldTheme;
