import React, { useEffect, useState } from 'react';

import IKnowledge from '@shared/models/knowledge.interface';
import './knowledge.scss';

const Knowlegde: React.FC<{
	id: number;
	selectedId: number | undefined;
	knowledge: IKnowledge;
	onSelected: (id: number) => void;
}> = ({ id, selectedId, knowledge, onSelected }) => {
	const [isSelected, setIsSelected] = useState(false);
	useEffect(() => {
		if (!isSelected) {
			return;
		}

		onSelected(id);
	}, [isSelected]);

	useEffect(() => {
		if (selectedId === id) {
			return;
		}

		setIsSelected(false);
	}, [selectedId]);

	return (
		<div className="knowledge" onClick={() => setIsSelected(true)}>
			<div className={`${isSelected ? 'knowledge-main-container-active' : 'knowledge-main-container'}`}>
				<knowledge.icon className="knowledge-icon" />
				<div className="knowledge-name">{knowledge.name}</div>
			</div>
		</div>
	);
};

export default Knowlegde;
