import { LuCode2, LuRocket, LuServer } from 'react-icons/lu';
import { HiOutlineUserGroup } from 'react-icons/hi2';

export const STEPS = [
	'home', 'à propos', 'compétences', 'expériences', 'contact'
];
export const HOME = {
	intro: {
		title: `Hello world!\nLet's create something awesome!`,
		welcome: `Développeur Full Stack, j'ai une forte appétence pour le clean code et la création d'expériences utilisateur fluides et intuitives.
		Vous pouvez {{link1}} ou {{link2}}.`,
		links: ['télécharger mon cv', 'en apprendre plus sur moi']
	},
	foreword: {
		title: 'À PROPOS',
		intro: `Bonjour ! Moi c'est Pierrick Viart, développeur web full stack avec un intérêt marqué pour les interfaces utilisateurs modernes et intuitives. Mon approche se concentre sur la compréhension approfondie des besoins des utilisateurs, ce qui me permet de concevoir des solutions efficaces et adaptées. Je prends en charge l’ensemble du processus, de la conception de l’interface à l’intégration des fonctionnalités, tout en veillant à optimiser le référencement et à assurer les déploiement. Je suis prêt à vous accompagner à chaque étape de votre projet digital pour garantir des résultats de qualité. Travaillons ensemble pour donner vie à vos idées !`
	},
	knowledges: {
		title: 'COMPéTENCES',
		knowledges: [
			{
				name: 'Front-end',
				completeName: 'Développement Front-end',
				description: `Maîtrise des technologies comme HTML, CSS, JavaScript et des frameworks modernes (React, Angular, Vue.js).\nCréation d'interfaces réactives et intuitives, optimisées pour tous types d'écrans (responsive design), avec une attention à la performance.`,
				icon: LuCode2
			},
			{
				name: 'Back-end',
				completeName: 'Développement Back-end',
				description:
					"Développement d'API REST ou GraphQL avec Node.js, Express, ou NestJS, et gestion de bases de données (MySQL, MongoDB, PostgreSQL).\n" +
					"Implémentation de systèmes sécurisés pour l'authentification et protection des données (ex. JWT, OAuth).",
				icon: LuServer
			},
			{
				name: 'DevOps',
				completeName: 'Déploiement, Tests et Assurance Qualité',
				description:
					"Utilisation d'outils CI/CD (Jenkins, GitLab CI) pour automatiser tests et déploiement.\n" +
					"Conteneurisation avec Docker, orchestration avec Kubernetes, et gestion de l'hébergement sur des plateformes comme AWS.\n" +
					"Écriture de tests unitaires et d'intégration (Jest, Mocha), debugging, et optimisation des performances.",
				icon: LuRocket
			},
			{
				name: 'Coopération',
				completeName: 'Coopération et Méthodologies Agiles',
				description:
					'Participation aux cérémonies agiles (Scrum, Kanban), gestion de projets avec Jira ou Trello, et communication efficace avec les parties prenantes pour traduire les besoins en solutions techniques.',
				icon: HiOutlineUserGroup
			}
		]
	},
	experiences: {
		title: 'EXPéRIENCES'
	},
	contact: {
		title: 'CONTACT',
		button: 'Envoyer',
	}
};
