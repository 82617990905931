import React, { forwardRef, useImperativeHandle, useState } from 'react';

import jobListe from '@constants/jobs';
import IJob from '@shared/models/job.interface';
import parseStringToDate from '@utils/date.utils';
import './timeline.scss';

const Timeline = forwardRef((_, ref) => {
  const [selectedJobId, setSelectedJobId] = useState<number>(0);
  const [displayJob, setDisplayJob] = useState<boolean>(true);

  useImperativeHandle(ref, () => ({
    resetDisplayJob: () => setSelectedJobId(0)
  }));

  function handleJobHover(id: number) {
    if (selectedJobId === id) {
      return;
    }
    if (displayJob) {
      setDisplayJob(false);
    }

    setTimeout(() => {
      setDisplayJob(true);
    }, 200);
    setSelectedJobId(id);
  }

  function sortJobsByDate(a: IJob, b: IJob): number {
    return (
      parseStringToDate(b.startDate).getTime() -
      parseStringToDate(a.startDate).getTime()
    );
  }

  function toShortDate(date: string | undefined): string {
    if (!date) {
      return '';
    }

    return parseStringToDate(date).toLocaleDateString('fr-FR', {
      month: 'short',
      year: 'numeric'
    });
  }

  return (
    <div className="timeline-main-container">
      <div className="timeline-item-container">
        {jobListe.sort(sortJobsByDate).map((job, index) => {
          return (
            <div
              key={index}
              className={`timeline-item ${selectedJobId === index ? 'current' : ''}`}
              onMouseEnter={() => handleJobHover(index)}
            >
              <div className="timeline-item-date">
                {toShortDate(job.startDate)}
              </div>

              <div className={'timeline-item-dot-container'}>
                <div className={`timeline-item-dot`}></div>
                <div className={`timeline-item-dot-link`}></div>
              </div>

              <div className="timeline-item-content">
                <div className="timeline-item-company">{job.company}</div>
                <div className="timeline-item-role">{job.role}</div>
              </div>
            </div>
          );
        })}
      </div>

      <div
        className={`selected-job-main-container pf-appear-animation ${displayJob ? 'active-fast' : ''}`}
      >
        {jobListe[selectedJobId] && (
          <div className="selected-job-container">
            <div className="selected-job-header-container">
              <div className="selected-job-header-title-role">
                {jobListe[selectedJobId].role}
              </div>

              <div className="selected-job-header-title">
                <div className="selected-job-header-date">
                  {toShortDate(jobListe[selectedJobId].startDate)}{' '}
                  {jobListe[selectedJobId].endDate &&
                    `- ${toShortDate(jobListe[selectedJobId].endDate)}`}
                </div>
                <div className="selected-job-header-title-company">
                  {jobListe[selectedJobId].company}
                </div>
              </div>
            </div>

            <div className="selected-job-description">
              {jobListe[selectedJobId].description}:
            </div>

            <div className="selected-job-realisations-container">
              <div className="selected-job-realisations-scroll-fade-top"></div>
              <div className="selected-job-realisations">
                {jobListe[selectedJobId].realisations.map(
                  (realisation, index) => {
                    return (
                      <li key={index} className="selected-job-realisation">
                        {realisation}
                      </li>
                    );
                  }
                )}
              </div>
              <div className="selected-job-realisations-scroll-fade"></div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
});

Timeline.displayName = 'Timeline';

export default Timeline;
