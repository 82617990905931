import React from 'react';
import { motion } from 'framer-motion';
import { FaCheck } from 'react-icons/fa';
import { AiOutlineClose } from 'react-icons/ai';

import { ReactComponent as Spinner } from '@assets/icons/spinner.svg';
import './loadingButton.scss';

export enum EButtonState {
  SUCCESS = 'success',
  ERROR = 'error',
  LOADING = 'loading',
  IDLE = 'idle'
}

const LoadingButton: React.FC<{
  text: string;
  buttonState: EButtonState;
  tryCounter?: number;
}> = ({ text, buttonState, tryCounter }) => {
  let buttonIcon;

  switch (buttonState) {
    case EButtonState.LOADING:
      buttonIcon = <Spinner className="pf-spinner" />;
      break;
    case EButtonState.SUCCESS:
      buttonIcon = <FaCheck />;
      break;
    case EButtonState.ERROR:
      buttonIcon = <AiOutlineClose />;
      break;
    case EButtonState.IDLE:
    default:
      break;
  }

  const wiggleAnimation = {
    x: [-5, 5, -5, 5, -2, 2, 0],
    transition: { duration: 0.3 }
  };

  return (
    <div className="loading-button-main-container">
      <motion.button
        className={`pf-button ${buttonState}`}
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        type="submit"
        transition={{ type: 'spring', stiffness: 200, damping: 17 }}
        disabled={buttonState === EButtonState.LOADING}
        key={tryCounter}
        animate={wiggleAnimation}
      >
        <div
          className={`loading-button-container ${buttonState === EButtonState.IDLE ? 'hide' : ''} ${buttonState === EButtonState.LOADING ? 'pf-animation_delay' : ''}`}
        >
          {buttonIcon}
        </div>
        <div
          className={`loading-button-container ${buttonState !== EButtonState.IDLE ? 'hide' : ''}`}
        >
          {text}
        </div>
      </motion.button>
    </div>
  );
};

export default LoadingButton;
