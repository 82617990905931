const parseStringToDate = (dateString: string): Date => {
	let [day, month, year] = [1, 1, 0];
	const splitDate = dateString.split('-').map((value) => parseInt(value));

	switch (splitDate.length) {
		case 1:
			[year] = splitDate;
			break;
		case 2:
			[month, year] = splitDate;
			month = month - 1;
			break;
		case 3:
			[day, month, year] = splitDate;
			month = month - 1;
			break;
	}

	return new Date(year, month , day );
};

export default parseStringToDate;
