import HomePage from './features/homePage/homePage.tsx';
import { HomeProvider } from './features/homePage/homePageContext';
import '@styles/styles.scss';

const App = () => {
  return (
    <div className="App">
      <HomeProvider>
        <HomePage />
      </HomeProvider>
    </div>
  );
};

export default App;
