import { skills } from '@constants/skills';
import Skill from '@shared/components/infiniteCarousel/skill/skill';

import './infiniteCarousel.scss';

const InfiniteCarousel = () => {
	return (
		<div className="infinite-carousel">
			<div className="infinite-carousel-main-container">
				<div className="infinite-carousel-skills-container">
					{skills.map((skill, index) => (
						<div key={index}>
							<Skill skill={skill} />
						</div>
					))}
					{skills.map((skill, index) => (
						<div key={index}>
							<Skill skill={skill} />
						</div>
					))}
					{skills.map((skill, index) => (
						<div key={index}>
							<Skill skill={skill} />
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default InfiniteCarousel;