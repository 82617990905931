import React from 'react';

import './scrollingMouse.scss';

const ScrollingMouse: React.FC<{ onButtonClick: () => void }> = ({
	onButtonClick,
}) => {
	return (
		<div>
			<div
				className="mouse-main-container"
				onClick={() => onButtonClick()}
			>
				<div className="mouse-scroll-container">
					<div className="mouse-scroll-button"></div>
				</div>
			</div>
		</div>
	);
};

export default ScrollingMouse;
