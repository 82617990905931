import React from 'react';
import ITextLink from '../models/text.interface';

const linkifyText = (text: string, links: ITextLink[]) => {
	const parts = text.split(/({{link1}}|{{link2}})/g);

	return parts.map((part, index) => {
		if (part === '{{link1}}' && links[0]) {
			return (
				<a key={index} className="pf-shiny-text" onClick={links[0].onClick}>
					{links[0].linkText}
				</a>
			);
		} else if (part === '{{link2}}' && links[1]) {
			return (
				<a key={index} className="pf-shiny-text" onClick={links[1].onClick}>
					{links[1].linkText}
				</a>
			);
		} else {
			return <span key={index}>{part}</span>;
		}
	});
};

export default linkifyText;