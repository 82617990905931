import { STEPS } from '@constants/texts';
import './homeStepper.scss';

const HomeStepper = ({
	activeStep,
	onStepClick,
}: {
	activeStep: number;
	onStepClick: (step: number) => void;
}) => {
	return (
		<div className="stepper-item">
			{STEPS.map((step, index) => (
				<div key={index} className="stepper-item-container">
					<div
						className={`step ${index === activeStep ? 'active' : ''}`}
						onClick={() => onStepClick(index)}
					></div>
					<div className="stepper-item-text pf-translate-x">{step}</div>
				</div>
			))}
		</div>
	);
};

export default HomeStepper;
