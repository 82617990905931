export interface IContactFormItem {
  label: string;
  defaultError: string;
  error?: string;
  required?: boolean;
  isValid?: boolean;
  isTouch?: boolean;
  type: ETextFieldType;
  rows?: number;
  value?: string;
  rules?: { type: EFormControlRules; props?: IRuleProps }[];
}

export interface IContactForm {
  [key: string]: IContactFormItem;
}

export enum ETextFieldType {
  TEXT = 'text',
  EMAIL = 'email',
  TEXTAREA = 'textarea'
}
export enum EFormControlRules {
  EMAIL,
  MIN_LENGTH
}

export interface IRuleProps {
  minLength?: number;
  error?: string;
}

export interface IFormRulesConfig {
  [EFormControlRules.MIN_LENGTH]: (
    value: string,
    props: IRuleProps
  ) => string | undefined;
  [EFormControlRules.EMAIL]: (value: string) => string | undefined;
}
