import React, { useEffect, useRef, useState } from 'react';

import Timeline from '@shared/components/timeline/timeline';
import useIntersectionObserver from '@shared/hooks/useIntersectionObserver';
import { useHome } from '@features/homePage/homePageContext';
import { IHomeComponentPropsInterface } from '@shared/models/component.interface';
import { HOME } from '@constants/texts';
import './homeExperiences.scss';

const HomeExperiences: React.FC<IHomeComponentPropsInterface> = ({ sectionId }) => {
  const [activateAnimation, setActivateAnimation] = useState(false);
  const timelineRef = useRef<{
    resetDisplayJob: () => void;
  }>(null);
  const elementRef = useRef(null);
  const isVisible = useIntersectionObserver(elementRef);
  const { setCurrentSectionId } = useHome();

  useEffect(() => {
    isVisible && setCurrentSectionId(sectionId);

    setTimeout(() => {
      setActivateAnimation(isVisible);
      if (!timelineRef?.current) {
        return;
      }

      timelineRef.current.resetDisplayJob();
    }, 400);
  }, [isVisible]);

  return (
    <div ref={elementRef} className="home-experiences pf-fullscreen">
      <div className="pf-section-container">
        <div
          className={`home-experiences-main-container pf-appear-animation ${activateAnimation ? 'active' : ''}`}
        >
          <div className="pf-section-title">{HOME.experiences.title}</div>

          <Timeline ref={timelineRef} />
        </div>
      </div>
    </div>
  );
};

export default HomeExperiences;
