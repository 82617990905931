import React, { useEffect, useRef, useState } from 'react';

import Knowledge from '@features/homePage/knowledges/knowledge/knowledge';
import useIntersectionObserver from '@shared/hooks/useIntersectionObserver';
import { useHome } from '@features/homePage/homePageContext';
import IKnowledge from '@shared/models/knowledge.interface';
import { IHomeComponentPropsInterface } from '@shared/models/component.interface';
import { HOME } from '@constants/texts';
import './homeKnowledges.scss';

const HomeKnowledges: React.FC<IHomeComponentPropsInterface> = ({ sectionId }) => {
  const knowledgeList = HOME.knowledges.knowledges;
  const [selectedKnowledge, setSelectedKnowledge] = useState<IKnowledge>();
  const [selectedId, setSelectedId] = useState<number>();
  const [isFirst, setIsFirst] = useState<boolean>(true);
  const [activateAnimation, setActivateAnimation] = useState(true);
  const elementRef = useRef(null);
  const isVisible = useIntersectionObserver(elementRef);
  const { setCurrentSectionId } = useHome();

  useEffect(() => {
    isVisible && setCurrentSectionId(sectionId);

    setTimeout(() => {
      setActivateAnimation(isVisible);
      setSelectedKnowledge(undefined);
      setSelectedId(undefined);
      setIsFirst(true);
    }, 400);
  }, [isVisible]);

  const handleKnowledgeSelected = (id: number) => {
    const knowledge = knowledgeList[id];

    if (!knowledge) {
      return;
    }

    setSelectedKnowledge(undefined);
    setSelectedId(id);
    setTimeout(
      () => {
        setSelectedKnowledge(knowledge);
      },
      isFirst ? 400 : 100
    );
    if (isFirst) {
      setIsFirst(false);
    }
  };

  return (
    <div ref={elementRef} className="home-knowledges pf-fullscreen">
      <div className="pf-section-container">
        <div className="home-knowledges-main-container">
          <div
            className={`pf-appear-animation ${activateAnimation ? 'active' : ''}`}
          >
            <div className="pf-section-title">{HOME.knowledges.title}</div>

            <div
              className={`home-knowledges-description ${isFirst ? 'first' : ''}`}
            >
              <div
                className={`pf-appear-animation ${selectedKnowledge ? 'active' : ''}`}
              >
                <div className="home-knowledges-description-name">
                  {selectedKnowledge?.completeName}
                </div>
                <div>{selectedKnowledge?.description}</div>
              </div>
            </div>

            <div className="home-knowledges-list">
              {knowledgeList.map((knowledge: IKnowledge, index) => (
                <div key={index} className="home-knowledges-item">
                  <Knowledge
                    id={index}
                    selectedId={selectedId}
                    knowledge={knowledge}
                    onSelected={handleKnowledgeSelected}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeKnowledges;
