import React, { useEffect, useRef, useState } from 'react';
import { TextField, ThemeProvider } from '@mui/material';

import useIntersectionObserver from '@shared/hooks/useIntersectionObserver';
import { useHome } from '@features/homePage/homePageContext';
import LoadingButton, {
  EButtonState
} from '@shared/components/loadingButton/loadingButton';
import { IHomeComponentPropsInterface } from '@shared/models/component.interface';
import { IContactForm } from '@shared/models/form.interface';
import { HOME } from '@constants/texts';
import { contactFormConf, formRules } from '@constants/contactForm';
import textFieldTheme from '@styles/textFieldTheme';
import './homeContact.scss';

const HomeContact: React.FC<IHomeComponentPropsInterface> = ({ sectionId }) => {
  const { setCurrentSectionId } = useHome();
  const formRef = useRef<HTMLFormElement>(null);
  const elementRef = useRef(null);
  const isVisible = useIntersectionObserver(elementRef);
  const [activateAnimation, setActivateAnimation] = useState(false);
  const [tryCounter, setTryCounter] = useState(0);
  const [buttonState, setButtonState] = useState<EButtonState>(
    EButtonState.IDLE
  );
  const [contactForm, setContactForm] = useState<IContactForm>(contactFormConf);

  const checkCustomValidity = (
    name: string,
    value: string
  ): { isValid: boolean; error?: string } => {
    let customErrorMsg = '';

    const customValidity = !(contactForm[name].rules || []).some((rule) => {
      const errorMsg = formRules[rule.type](value, rule.props || {});
      const isValid = !errorMsg;

      !isValid && (customErrorMsg = errorMsg);
      return !isValid;
    });

    return { isValid: customValidity, error: customErrorMsg };
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name, validity, value } = e.target;
    const { isValid: customValidity = true, error: customErrorMsg = '' } =
      validity.valid ? checkCustomValidity(name, value) : {};

    setContactForm((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        isValid: validity.valid && customValidity,
        error:
          (!customValidity && customErrorMsg) || contactForm[name].defaultError,
        isTouch: true,
        value
      }
    }));
  };

  const isFormValid = () => {
    return !Object.values(contactForm).some((item) => !item.isValid);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!formRef.current) {
      return;
    }

    const textFields = formRef.current.querySelectorAll('input, textarea');

    textFields.forEach((textField) => {
      const inputElement = textField as HTMLInputElement | HTMLTextAreaElement;
      inputElement.focus();
      inputElement.blur();
    });

    if (!isFormValid()) {
      setTryCounter((prevState) => (prevState + 1) % 2);
      return;
    }

    const {
      userName: { value: name },
      userEmail: { value: email },
      userMessage: { value: message }
    } = contactForm;
    console.log('SEND', { name, email, message });

    setButtonState(EButtonState.LOADING);
    setTimeout(() => {
      setButtonState(EButtonState.SUCCESS);
    }, 2000);
  };

  useEffect(() => {
    isVisible && setCurrentSectionId(sectionId);

    setTimeout(() => {
      setActivateAnimation(isVisible);
    }, 400);
  }, [isVisible]);

  return (
    <div ref={elementRef} className="home-contact pf-fullscreen">
      <div className="pf-section-container">
        <div
          className={`home-contact-main-container pf-appear-animation ${activateAnimation ? 'active' : ''}`}
        >
          <div className="pf-section-title">{HOME.contact.title}</div>

          <ThemeProvider theme={textFieldTheme}>
            <div className="home-contact-form-container">
              <form
                ref={formRef}
                className="home-contact-form"
                onSubmit={handleSubmit}
                noValidate
              >
                {Object.keys(contactForm).map((name) => {
                  const item = contactForm[name];
                  return (
                    <TextField
                      key={name}
                      type={item.type}
                      id={name}
                      name={name}
                      label={item.label}
                      variant="filled"
                      multiline={!!item.rows}
                      rows={item.rows}
                      required={item.required}
                      error={!item.isValid && item.isTouch}
                      onBlur={handleBlur}
                      helperText={!item.isValid && item.isTouch && item.error}
                    />
                  );
                })}

                <LoadingButton
                  text={HOME.contact.button}
                  buttonState={buttonState}
                  tryCounter={tryCounter}
                ></LoadingButton>
              </form>
            </div>
          </ThemeProvider>
        </div>
      </div>
    </div>
  );
};

export default HomeContact;
