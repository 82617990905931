import {
	FaJsSquare,
	FaReact,
	FaNodeJs,
	FaDatabase,
	FaHtml5,
	FaCss3Alt,
	FaSass,
	FaBootstrap,
	FaGitAlt,
	FaDocker,
	FaAws,
	FaPython,
	FaCuttlefish
} from 'react-icons/fa';

import { ISkill } from '@shared/models/skill.interface';

export const skills: ISkill[] = [
	{
		name: 'JavaScript',
		icon: FaJsSquare
	},
	{
		name: 'TypeScript',
		icon: FaJsSquare
	},
	{
		name: 'React',
		icon: FaReact
	},
	{
		name: 'Node.js',
		icon: FaNodeJs
	},
	{
		name: 'Express',
		icon: FaNodeJs
	},
	{
		name: 'MongoDB',
		icon: FaDatabase
	},
	{
		name: 'HTML',
		icon: FaHtml5
	},
	{
		name: 'CSS',
		icon: FaCss3Alt
	},
	{
		name: 'Sass',
		icon: FaSass
	},
	{
		name: 'Material-UI',
		icon: FaBootstrap
	},
	{
		name: 'Jest',
		icon: FaJsSquare
	},
	{
		name: 'Cypress',
		icon: FaJsSquare
	},
	{
		name: 'Git',
		icon: FaGitAlt
	},
	{
		name: 'Docker',
		icon: FaDocker
	},
	{
		name: 'AWS',
		icon: FaAws
	},
	{
		name: 'Heroku',
		icon: FaAws
	},
	{
		name: 'Python',
		icon: FaPython
	},
	{
		name: 'C',
		icon: FaCuttlefish
	},
	{
		name: 'C++',
		icon: FaCuttlefish
	}
];
